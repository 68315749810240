/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./fonts.css"
import "./layout.css"
import "./custom.css"

// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          tagline
        }
      }
    }
  `);
  
  return (
    <>
      <Grid id="mainGrid" container spacing={1} style={{flex: `grow`}}>
        <Grid item xs={12}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            siteTagline={data.site.siteMetadata.tagline}
          />
          <main>{children}</main>
        </Grid>
      </Grid>
      <footer>
        © {new Date().getFullYear()} Solytica Corp. All Rights Reserved.
      </footer>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
