import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';

const Header = ({ siteTitle, siteTagline }) => (
  <header>
    <Box id="HeaderBox">
      <Hidden only="xs">
        <h1><Link to="/" >{siteTitle}</Link></h1>
        <h5>{siteTagline}</h5>
      </Hidden>
      <Hidden smUp>
        <Paper id="HeaderPaper">
          <h1><Link to="/" >{siteTitle}</Link></h1>
          <h5>{siteTagline}</h5>
        </Paper>
      </Hidden>
    </Box>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteTagline: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  siteTagline: ``,
};

export default Header;
